import React from 'react'
import { H2, H4, Paragraph1 } from '@styles/Global.styles'
import { Grid } from 'semantic-ui-react'
import { CheckoutSelectionCard } from '@components/CheckoutSelectionCard'
import { PlateOwnerSelectionSubHeading } from './PlateOwnerSelection.styles'
import { CheckoutSelectionCardModel } from '@models/Checkout/CheckoutSelectionCard'
import { KpButton } from '@elements/index'
import theme from '@styles/theme'
import { Checkout } from '@models/Checkout/Checkout'
import { navigate } from 'gatsby'
import { TransactionTypesSetting } from '@models/ProductModels'

export type PlateOwnerSelectionProps = {
    heading: string
    subHeading: string
    body: string
    applicantCard: CheckoutSelectionCardModel
    giftingCard: CheckoutSelectionCardModel
    updateOwnerIsApplicant: (ownerIsApplicant:boolean) => void
    updateManufactureNow: (manufactureNow:boolean)=>void
    updateIsGifting: (isGifting: boolean) => void
    updateGiftingType: (giftingType: number) => void
    checkout: Checkout
    transactionTypeId: number
}

interface States {
    ownerIsApplicant: boolean
}

class MobilePlateOwnerSelection extends React.Component<PlateOwnerSelectionProps, States>{
    constructor(props: PlateOwnerSelectionProps) {
        super(props)
        this.state = {
            ownerIsApplicant: false
        }
    }

    selectApplicantOption = () => {
        this.props.updateOwnerIsApplicant(true);
        if(!this.props.checkout.isLayby) this.props.updateManufactureNow(false);

        this.setState({
            ownerIsApplicant: true
        }, () => {
            setTimeout(() => {
                var url = (this.props.checkout.ownerIsApplicant && !this.props.checkout.isLayby) ? '/plate-manufacture-selection/' : '/personal-details/'
                navigate(url)
            }, 500);
        })


    }

    selectGiftingOption = () => {
        this.props.updateOwnerIsApplicant(false);
        if(!this.props.checkout.isLayby) this.props.updateManufactureNow(false);
        if(this.props.transactionTypeId != TransactionTypesSetting.New.id) {
            this.props.updateIsGifting(true);        
            this.props.updateGiftingType(1);       
        }

        setTimeout(() => {
            var url = (this.props.transactionTypeId != TransactionTypesSetting.New.id && !this.props.checkout.isLayby) ? '/gifting-options-selection/' : '/gifting-selection/'
            navigate(url)
        }, 500);
    }

    render(){

        const {heading, subHeading, body, applicantCard, giftingCard, checkout} = this.props;

        return (
            <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <H2>{subHeading}</H2>
                    <Paragraph1>
                        {body}
                    </Paragraph1>
                    <Grid stretched>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <CheckoutSelectionCard card={applicantCard} onSelect={() => this.selectApplicantOption()} isSelected={this.state.ownerIsApplicant} hideButton={true}></CheckoutSelectionCard>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <CheckoutSelectionCard card={giftingCard} onSelect={() => this.selectGiftingOption()} isSelected={!checkout.ownerIsApplicant} hideButton={true}></CheckoutSelectionCard>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        );
    }
}
export default MobilePlateOwnerSelection