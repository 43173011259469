import React from 'react'
import {
    CheckoutSelectionCardContainer,
    CheckoutSelectionCardIndicator,
    CheckoutSelectionCardHeading,
    CheckoutSelectionCardButton,
    RadioGroup

} from './CheckoutSelectionCard.styles'
import theme from '@styles/theme'
import { Icon, Radio } from 'semantic-ui-react'
import { CheckoutSelectionCardModel } from '@models/Checkout/CheckoutSelectionCard'
import { Checkout } from '@models/Checkout/Checkout'

export type CheckoutSelectionCardProps = {
    card: CheckoutSelectionCardModel
    secondCard: CheckoutSelectionCardModel
    onSelect: Function
    isSelected: boolean
    manufactureSelection?: boolean
    expressCheckout?: boolean
    hideButton?: boolean
    updateIsGifting: (isGifting: boolean) => void
    updateGiftingType: (giftingType: number) => void
    updateRadio: (isGifting: boolean) => void
    checkout: Checkout
}



class CheckoutSelectionCardWithRadios extends React.Component<CheckoutSelectionCardProps>{
    
    state = {
        giftingType : 0,
        isGifting : this.props.checkout.isGifting
    }

    handleChange(isGifting: boolean): void  {
        this.props.onSelect()
        setTimeout(() => {
            if (this.props.isSelected) {
                this.setState({isGifting: isGifting})
                this.props.updateIsGifting(isGifting);
                this.props.updateGiftingType(isGifting ? 1 : 0);
                this.props.updateRadio(isGifting);
            }
        }, 100);
    }
    
    render() {

        const { heading, body } = this.props.card;
        const { isSelected, manufactureSelection, hideButton = false, secondCard } = this.props;

        let isSelectedClass = isSelected;
        if(manufactureSelection && manufactureSelection == true){
            isSelectedClass = false
        }

        return (
            <CheckoutSelectionCardContainer className={isSelectedClass ? 'selected' : isSelected ? 'selectedMan' : ''} onClick={() => hideButton && this.props.onSelect()}>
                <div>
                    <CheckoutSelectionCardHeading>{heading}</CheckoutSelectionCardHeading>
                    <RadioGroup>
                        <Radio label={secondCard.heading} checked={this.state.isGifting} onChange={() => this.handleChange(true)} name='radiogroup'/>
                        <br/>
                        <Radio label={body} checked={!this.state.isGifting} onChange={() => this.handleChange(false)} name='radiogroup'/>
                    </RadioGroup>                    
                </div>
                {!hideButton &&
                <div>
                    {
                        isSelected &&
                        <CheckoutSelectionCardButton id="csc-selected" buttonType='primary' color={theme.brand.colors.green}>SELECTED</CheckoutSelectionCardButton>
                    }
                    {
                        !isSelected &&
                        <CheckoutSelectionCardButton id="csc-select" buttonType='secondary' color={theme.brand.colors.black} onClick={this.props.onSelect}>SELECT</CheckoutSelectionCardButton>
                    }
                </div>}
                {
                    isSelected &&
                    <CheckoutSelectionCardIndicator>
                        <Icon name='check' size='small' />
                    </CheckoutSelectionCardIndicator>
                }
            </CheckoutSelectionCardContainer>
        );
    }
    
}



export default CheckoutSelectionCardWithRadios